import { ProductNameConstants } from '../constants';

export function productNameReducer(state = {}, action) {
    switch (action.type) {
        case ProductNameConstants.GET_PRODUCT_NAME:
            return {
                items: action.productName
            };
        case ProductNameConstants.SET_PRODUCT_NAME:
            return {
                items: action.productName
            };
        default:
            return state
    }
}